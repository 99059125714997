
/* FONTS */
/* Roboto */
@font-face {
  font-family: Roboto;
  src: url("fonts/Roboto/Roboto-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: Roboto;
  src: url("fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Roboto;
  src: url("fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Roboto;
  src: url("fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
}
/* Roboto Mono */
@font-face {
  font-family: Roboto Mono;
  src: url("fonts/RobotoMono/RobotoMono-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: Roboto Mono;
  src: url("fonts/RobotoMono/RobotoMono-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: Roboto Mono;
  src: url("fonts/RobotoMono/RobotoMono-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: Roboto Mono;
  src: url("fonts/RobotoMono/RobotoMono-Regular.ttf");
  font-weight: 400;
}

/* TEXT SELECTIONS */
::-moz-selection {
  background-color: var(--grey-color);
}
::selection {
  background-color: var(--grey-color);
}

/* GENERAL */
html, body, #root, .app {
  min-height: 100vh;
  min-width: 100%;
  overflow-x: hidden;
  font-family: Roboto;
  font-weight: 400;

  scrollbar-width: none;
}
body, a, a:hover {
  cursor: none;
}
button {
  padding: 0;
} 
.hide {
  display: none;
}
#cursor {
  top: 50%;
  left: 50%;
  position: fixed;
  z-index: 5;
  pointer-events: none;
  fill: var(--text-color);
  stroke: var(--text-color);
  stroke-width: 0;
  mix-blend-mode: difference;
  transition: stroke-width .3s ease-in-out;
}
#cursor.cursor-hover {
  stroke-width: 50%;
}
button:not(:disabled) {
  cursor: none;
}
::-webkit-scrollbar {
  display: none;
}
.app {
  color: var(--text-color);
  font-size: var(--medium-font);
  padding: 0;
  display: flex;
  flex-direction: column;
}
#wrapper {
  position: relative;
  flex: 1;
  display: flex;
  margin-top: var(--header-height);
  margin-bottom: var(--footer-height);
}
#content {
  position: relative;
  flex: 1;
  display: flex;
}

/* HEADER */
header {
  display: flex;
  justify-content: space-between;
  background-color: var(--bg-color);
  font-size: var(--big-font);
  position: fixed;
  z-index: 1;
  width: 100%;
  height: var(--header-height);
}
.home-button, .mobile-nav-button-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.rect {
  margin: 0 20px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 3px solid var(--text-color);
  transform: rotate(0deg);
  transform-origin: center;
  transition: transform .2s ease-in;
}
.rect:hover {
  transform: rotate(-90deg);
}
.rect::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: 46px;
  height: 46px;
  background-color: var(--text-color);
  transform: scaleY(0);
  transform-origin: top;
  transition: transform .2s ease-in;
}
.rect:hover::before {
  transform: scaleY(1);
}
#nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 20px 10px auto;
}
.nav-button {
  border: none;
  position: relative;
  text-decoration: none;
  background-color: inherit;
  margin: 0 15px;
  padding: 4px 0;
  font-family: Roboto Mono;
  font-weight: 300;
}
.nav-button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--text-color);
  transform: scaleX(0);
  transition: transform .3s ease;
}
.nav-button:hover::before {
  transform: scaleX(1);
}
.nav-button, .nav-button:hover {
  color: var(--text-color);
}
.mobile-nav-button-cont {
  
}
.mobile-nav-button {
  border: none;
  background-color: var(--dark-color);
  margin: 0 20px;
  height: 50px;
  width: 50px;
}
.mobile-nav-button svg {
  fill: var(--text-color);
  height: 25px;
}


/* MOBILE NAV */
#mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  background-color: rgba(0, 0, 0, .5);
  animation: .25s fade-in ease-in-out;
}
.close-menu, #mobile-nav #nav {
  flex: 1;
}
#mobile-nav #nav {
  flex-direction: column;
  margin: 0;
  background-color: var(--bg-color);
  animation: .25s flex-grow ease-in-out;
}
#mobile-nav #nav .nav-button {
  font-size: 24px;
  margin: 20px 15px;
} 


/* CANVAS */
#canvas {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--dark-color);
  overflow: hidden;
}


/* HOME */
#home {
  margin-top: 100px;
}


/* PROJECT */
.project {
  margin-top: 40px;
  padding: 25px;
  display: flex;
  flex-direction: row;
}
#projects .row:last-child .project {
  margin-bottom: 40px;
}
.fade-anim {
  opacity: 0;
}
.project-img {
  flex: 0;
  text-align: center;
}
.project-img img {
  width: 300px;
  height: 300px;
  pointer-events: none;
}
.project-content {
  flex: 1;
  padding: 0 20px;
}
.project-title {
  font-family: Roboto Mono;
  font-weight: 400;
  font-size: var(--big-font);
  margin-bottom: 10px;
}
.project-desc {
  color: var(--lgrey-color);
}

.fade-in-left {
  animation: fade-in-left 1.4s ease-out forwards;
}
.fade-in-right {
  animation: fade-in-right 1.4s ease-out forwards;
}

/* ABOUT */
#about {
  margin: 40px 0;
  width: 100%;
  animation: fade-in 1.4s ease-out forwards;
}
#about .about-container {
  display: flex;
  flex-direction: column;
}
.about-title, .contact-title {
  font-family: Roboto Mono;
  font-weight: 400;
  color: var(--text-color);
  font-size: var(--big-font);
  margin-bottom: 10px;
}
#about section:not(.bottom) {
  flex: 1;
}
section.bottom {
  height: 100px;
  text-align: center;
}
.about-content {
  display: flex;
}
.about-img {
  width: 400px;
}
.about-img img {
  width: 100%;
}
.about-img img:hover {
  animation: img-hue-rotate .6s ease-in-out;
}
.about-text, .contact-text {
  flex: 1;
  padding: 0 40px;
}
.about-text.solo, .contact-text.solo {
  padding: 10px 0 0 0;
}
.bottom-text {
  font-family: Roboto Mono;
  height: 20px;
  font-size: var(--small-font);
}

/* CONTACT */
#contact {
  margin: 40px 0;
  width: 100%;
  animation: fade-in 1.4s ease-out forwards;
}
#contact .contact-container {
  display: flex;
  flex-direction: column;
}
#contact section:not(.bottom) {
  flex: 1;
}
.contact-elem {
  margin-bottom: 0;
}
a.contact-elem {
  color: var(--text-color);
  transition: .3s;
}
a.contact-elem:hover {
  color: var(--lgrey-color);
}



/* LOGO ANIMATIONS */
/* about (react) logo */
.about-logo, .linkedin-logo {
  fill: var(--text-color);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--text-color);
}
.about-logo {
  height: 100px;
  stroke-width: 5;
}
.logo-anim {
  fill-opacity: 0;
  animation: stroke-anim 3s ease-in-out forwards, fill-anim 1s linear 3s forwards;
}
.about-logo .logo-anim {
  stroke-dashoffset: 2500;
  stroke-dasharray: 2500;
}
.linkedin-logo .logo-anim {
  stroke-dashoffset: 900;
  stroke-dasharray: 900;
}
/* linked in logo */
.linkedin-logo {
  width: 200px;
  stroke-width: 4;
  stroke-miterlimit: 2;
  transition: .3s;
}
a:hover .linkedin-logo {
  fill: var(--grey-color);
  stroke:  var(--grey-color);
}

/* FOOTER */
footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: var(--bg-color);
  position: fixed;
  z-index: 1;
  width: 100%;
  bottom: 0;
  height: var(--footer-height);
}
.lgbtq-bar {
  display: flex;
  flex-direction: row;
  height: 5px;
  border-radius: 10px 10px 0 0;
  transition: .4s;
}
footer:hover .lgbtq-bar, footer:hover .lgbtq-bar div {
  height: 20px;
  animation: scale-anim .4s ease-in-out;
}
.lgbtq-bar div {
  flex: 1;
}
.red {
  border-top-left-radius: 5px;
  background-color: var(--lgbtq-red);
}
.orange {
  background-color: var(--lgbtq-orange);
}
.yellow {
  background-color: var(--lgbtq-yellow);
}
.green {
  background-color: var(--lgbtq-green);
}
.blue {
  background-color: var(--lgbtq-blue);
}
.purple {
  border-top-right-radius: 5px;
  background-color: var(--lgbtq-purple);
}
.footer-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto Mono;
  font-weight: 200;
}

/* ANIMATIONS */
@keyframes img-hue-rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(180deg) contrast(1.3);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
@keyframes fade-in-left {
    from { 
      opacity: .25;
      transform: translateX(-100px);
    }
    to { 
      opacity: 1;
      transform: translateX(0);
    }
}
@keyframes fade-in-right {
    from { 
      opacity: .25;
      transform: translateX(100px);
    }
    to   { 
      opacity: 1;
      transform: translateX(0);
    }
}
@keyframes fade-in {
  from {
    opacity: .25;
  }
  to {
    opacity: 1;
  }
}
@keyframes scale-anim {
  0% {
    height: 5px;
  }
  80% {
    height: 22px;
  }
  100% {
    height: 20px;
  }
}
@keyframes stroke-anim {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes fill-anim {
    to {
        fill-opacity: 1;
    }
}
@keyframes flex-grow {
  from {
    flex-grow: 0;
  }
  to {
    flex-grow: 1;
  }
}


/* TABLET */
@media screen and (max-width: 990px) {

  /* APP */
  #cursor {
    display: none;
  }

  /* PROJECTS */
  .project {
    flex-direction: column;
    padding: 0;
    margin: 30px auto 10px auto;
  }  
  .project-content {
    margin-top: 20px;
  }
  
  /* ABOUT */
  .about-content {
    flex-direction: column;
  }
  .about-img {
    text-align: center;
    margin: 0 auto;
  }
  .about-text {
    padding: 0;
    margin: 20px 0;
  }
}

/* MOBILE */
@media screen and (max-width: 780px) {

  /* PROJECTS */
  .project-img img {
    width: 200px;
    height: 200px;
  }

  /* ABOUT */
  .about-img {
    width: 100%;
  }
  .about-img img {
    width: 250px;
    height: 250px;
  }

}

/* deactivate sticky hover/onclick effect on mobile */
/*
@media (hover: hover) and (pointer: fine) {
  .rect:hover {
    transform: rotate(-90deg);
  }
  .rect:hover::before {
    transform: scaleY(1);
  }
}
*/


/* VARIABLES */
:root {
  /* colors */
  --main-color: #1a1a1a;
  --sec-color: #CFDBD5;

  --dark-color: #111;
  --text-color: #c2c2c2;
  --bg-color: var(--main-color);
  --grey-color: #444;
  --lgrey-color: #888;

  /* lgbtq colors */
  --lgbtq-red: #FF0018;
  --lgbtq-orange: #FFA52C;
  --lgbtq-yellow: #FFFF41;
  --lgbtq-green: #008018;
  --lgbtq-blue: #0000F9;
  --lgbtq-purple: #86007D;

  /* fonts */
  --small-font: 14px;
  --medium-font: 18px;
  --head-font: 21px;
  --big-font: 24px;
  --xl-font: 32px;

  /* misc */
  --header-height: 90px;
  --footer-height: 60px;

}